import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import NotebookIcon from "../../static/icons/notebook-large.svg"

import Module from "../components/Module"

function Title(props) {
  let showTitle = process.env.SHOW_NAVIGATION
  if (showTitle === "true") {
    return (
      <div style={{
          display: "flex",
          borderBottom: "1px solid hsla(0,0%,0%,0.2)",
          marginBottom: "1.58rem"
        }}>
        <h1 style={{ fontSize: "300%", flexGrow: 1, border: "none", marginBottom: 0, paddingBottom: 0 }}>{props.title}</h1>
        <img
          src={NotebookIcon}
          alt="Curriculum Icon"
          style={{
            height: "96px",
            flexBasis: "96px",
            marginRight: "0.2em",
            marginTop: "3.6rem",
            float: "right",
          }}
        />
        
      </div>
    )
  } else {
    return "";
  }
}

export default function Template({ data, pageContext }) {
  const { allJson, allMarkdownRemark } = data
  let node = allJson.nodes[0];
  let modules = node.modules || [];
  const { html } = allMarkdownRemark.nodes[0];

  let showOnlyContent = process.env.SHOW_ONLY_CONTENT
  if (showOnlyContent === "true") {
    if (typeof window !== 'undefined') {
      window.location = '/404/index.html';
    }
    return null;
  }

  let title = "Java Foundations Curriculum";
  let emoji = "📒"
  let pagePath = "/curriculum/index.html";

  return (
    <div className="lesson-container">
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="robots" content="noindex,nofollow"></meta>
        <title>{title}</title>
        <link
          rel="shortcut icon"
          href={"https://emoji.beeimg.com/" + emoji + "/144/twitter"}
        />
      </Helmet>
      <div
        className="lesson"
        style={{
          maxWidth: "60rem",
          marginLeft: "auto",
          marginRight: "auto",
          padding: "1.5rem 1.125rem",
          position: "relative",
        }}
      >
        <header className="lesson-header">
          <Title title={title} emoji={emoji}/>
        </header>
        <main className="lesson-main">
          <div
              className="lesson-content"
              dangerouslySetInnerHTML={{ __html: html }}
            />
        </main>
        {
          modules.map((value, index) => {
            return (
              <Module module={value} moduleIndex={index} key={index}/>
            )
          })
        }
      </div>
    </div>
  )
}

export const pageQuery = graphql`
{
  allJson(limit: 1000) {
    nodes {
      modules {
        name
        moduleOverview {
          path
          title
          type
          status
        }
        submodules {
          name
          submoduleOverview {
            path
            title
            type
            status
          }
          sections {
            name
            pages {
              path
              title
              type
              status
            }
          } 
        }
      }
    }
  }
  allMarkdownRemark(filter: {frontmatter: {path: {eq: "/homepage/index.html"}}}) {
    nodes {
      html
    }
  }
}
`
